<template>
  <div class="menu">
      <a class="close" @click="closeMenu">
          <img src="~/assets/img/common/btn-close-menu.svg"/>
      </a>
      <div class="list">
          <div class="section-top">
              <ul>
                  <li><a @click="moveto('/')">トップ</a></li>
                  <li><a @click="moveto('/category')">カテゴリー</a></li>
                  <li><a @click="moveto('/my')">マイページ</a></li>
              </ul>
          </div>
          <div class="section-footer">
              <ul>
                  <li><NuxtLink @click="moveto('/credits?callback=my')" >クレジットの追加</NuxtLink></li>
                  <li><NuxtLink to="https://zinc-walkover-4ba.notion.site/c14d86bf793c49548181aad0268ec89f?pvs=4" >使い方の説明</NuxtLink></li>
                  <li><NuxtLink to="https://lp.heartf.com/" >オンラインスクール</NuxtLink></li>
                  <li><NuxtLink to="https://optimium.io/" >運営会社</NuxtLink></li>
              </ul>
          </div>
      </div>
  </div>
</template>
<script setup>
const emits = defineEmits();

const router = useRouter();
function moveto(url){
    // router.push(url);
    navigateTo(url);
    closeMenu();
}
function closeMenu(){
    emits('closeMenu');
}
</script>
<style lang="scss" scoped>
.menu{
  background-color:#166A6A;
  height: 100dvh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;

  > .close{
      position: absolute;
      top: 12px;
      right: 12px;
      
  }
  > .list{
      height: calc(100dvh - 24px); 
      max-height: calc(100dvh - 24px); 
      width: 80dvw;
      overflow-y: auto;
      &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
            width: 0;
            height: 0;
            background: transparent;    
        }
    
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 40px;

      > .section-top{
          font-weight: 700;
          font-size: 32px;
          letter-spacing: 0.1em;
          line-height: 3.0;
      }
     
      > .section-footer{
          font-weight: 700;
          font-size: 24px;
          line-height: 3.0;
      }

      & li{
          list-style: none;
          & a{
              text-decoration: none;
              color: white;
              display: flex;
              & img {
                  padding-right: 10px;
              }
          }
      }
  }
}
</style>